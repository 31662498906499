var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "intersect",
            rawName: "v-intersect",
            value: _vm.intersectionObserver,
            expression: "intersectionObserver",
          },
        ],
      }),
      _c(
        "div",
        { class: _vm.isIntersecting ? "" : "types-fixed" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "12", sm: "4" },
                    },
                    [
                      !_vm.salesForce
                        ? _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              items: _vm.$isError(_vm.resourcePools)
                                ? []
                                : _vm.resourcePools,
                              loading: _vm.fetching.resourcePools,
                              disabled: _vm.fetching.resourcePools,
                              error: _vm.$isError(_vm.resourcePools),
                              "error-messages": _vm.getErrorMsg(
                                _vm.resourcePools
                              ),
                              "item-text": "long_id",
                              "item-value": "rpid",
                              dense: "",
                              label: "Resource pool",
                              placeholder: "Type to search",
                              "hide-details": "auto",
                              "auto-select-first": "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getResourceList("orgPools")
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        {
                                          class: data.item.deleted && "deleted",
                                        },
                                        [_vm._v(_vm._s(data.item.long_id))]
                                      ),
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "ml-3",
                                          attrs: {
                                            outlined: "",
                                            color: "grey lighten-1",
                                            "x-small": "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.userRole(
                                                data.item.is_rp_manager
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4210685680
                            ),
                            model: {
                              value: _vm.selectedResourcePoolId,
                              callback: function ($$v) {
                                _vm.selectedResourcePoolId = $$v
                              },
                              expression: "selectedResourcePoolId",
                            },
                          })
                        : [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-1",
                                attrs: {
                                  icon: "",
                                  disabled: !_vm.selectedRP.salesforce_uri,
                                  href: _vm.selectedRP.salesforce_uri,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v("mdi-salesforce"),
                                ]),
                              ],
                              1
                            ),
                            _c("v-autocomplete", {
                              attrs: {
                                outlined: "",
                                items: _vm.$isError(_vm.resourcePools)
                                  ? []
                                  : _vm.resourcePools,
                                loading: _vm.fetching.resourcePools,
                                disabled: _vm.fetching.resourcePools,
                                error: _vm.$isError(_vm.resourcePools),
                                "error-messages": _vm.getErrorMsg(
                                  _vm.resourcePools
                                ),
                                "item-text": "salesforce_long_id",
                                "item-value": "rpid",
                                dense: "",
                                label: "Salesforce pool",
                                placeholder: "Type to search",
                                "hide-details": "auto",
                                "auto-select-first": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getResourceList("orgPools")
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        {
                                          class:
                                            (data.item.deleted && "deleted") ||
                                            (data.item.not_in_salesforce &&
                                              "non-existent"),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.item.salesforce_long_id
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.selectedResourcePoolId,
                                callback: function ($$v) {
                                  _vm.selectedResourcePoolId = $$v
                                },
                                expression: "selectedResourcePoolId",
                              },
                            }),
                          ],
                      _c("contacts-menu", {
                        attrs: {
                          level: "rp",
                          rpId: _vm.selectedResourcePoolId,
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "pl-6",
                          class: { hidden: !_vm.showOrgPools },
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_right")])],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "12", sm: "4" },
                    },
                    [
                      _vm.showOrgPools
                        ? _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              items: _vm.$isError(_vm.orgPools)
                                ? []
                                : _vm.orgPools,
                              loading: _vm.fetching.orgPools,
                              disabled: _vm.fetching.orgPools,
                              error: _vm.$isError(_vm.orgPools),
                              "error-messages": _vm.getErrorMsg(_vm.orgPools),
                              "item-text": "long_id",
                              "item-value": "oid",
                              dense: "",
                              label: "Organization",
                              placeholder: "Type to search",
                              "hide-details": "auto",
                              clearable: "",
                              "auto-select-first": "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.getResourceList("spacePools")
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        {
                                          class: data.item.deleted && "deleted",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.long_id) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3369515341
                            ),
                            model: {
                              value: _vm.selectedOrgId,
                              callback: function ($$v) {
                                _vm.selectedOrgId = $$v
                              },
                              expression: "selectedOrgId",
                            },
                          })
                        : _vm._e(),
                      _vm.showOrgPools
                        ? _c("contacts-menu", {
                            attrs: {
                              level: "org",
                              rpId: _vm.selectedResourcePoolId,
                              orgId: _vm.selectedOrgId,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "pl-6",
                          class: { hidden: !_vm.showSpacePools },
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_right")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-end",
                      attrs: { cols: "12", sm: "4" },
                    },
                    [
                      _vm.showSpacePools
                        ? _c("v-autocomplete", {
                            attrs: {
                              outlined: "",
                              items: _vm.$isError(_vm.spacePools)
                                ? []
                                : _vm.spacePools,
                              loading: _vm.fetching.spacePools,
                              disabled: _vm.fetching.spacePools,
                              error: _vm.$isError(_vm.spacePools),
                              "error-messages": _vm.getErrorMsg(_vm.spacePools),
                              "item-text": "long_id",
                              "item-value": "sid",
                              dense: "",
                              label: "Space",
                              placeholder: "Type to search",
                              "hide-details": "auto",
                              clearable: "",
                              "auto-select-first": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        {
                                          class: data.item.deleted && "deleted",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.long_id) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3369515341
                            ),
                            model: {
                              value: _vm.selectedSpaceId,
                              callback: function ($$v) {
                                _vm.selectedSpaceId = $$v
                              },
                              expression: "selectedSpaceId",
                            },
                          })
                        : _vm._e(),
                      _vm.showSpacePools
                        ? _c("contacts-menu", {
                            attrs: {
                              level: "space",
                              rpId: _vm.selectedResourcePoolId,
                              spaceId: _vm.selectedSpaceId,
                            },
                          })
                        : _vm._e(),
                      _vm.isAccountManager || _vm.isSysadmin
                        ? _c(
                            "div",
                            { staticClass: "pl-6" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": true,
                                    "offset-y": "",
                                    bottom: "",
                                    left: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    color: "secondary",
                                                    icon: "",
                                                    dark: "",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4024974610
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "", nav: "" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c("v-list-item-title", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    label:
                                                      "Use Salesforce names",
                                                  },
                                                  model: {
                                                    value: _vm.salesForce,
                                                    callback: function ($$v) {
                                                      _vm.salesForce = $$v
                                                    },
                                                    expression: "salesForce",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { staticClass: "mt-3 overflow-hidden" },
                    [
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          attrs: { color: "primary" },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        _vm._l(_vm.visibleTabs, function (tab, index) {
                          return _c(
                            "v-tab",
                            { key: `tab-${index}` },
                            [
                              _c("v-icon", { attrs: { small: "", left: "" } }, [
                                _vm._v(_vm._s(tab.icon)),
                              ]),
                              _vm._v(" " + _vm._s(tab.label) + " "),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      staticClass: "mt-7 overflow-visible",
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _vm.showDatePicker
                        ? _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "4",
                                        lg: "3",
                                        xl: "2",
                                      },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          items: _vm.dateRangeOptions,
                                          dense: "",
                                          "menu-props": { offsetY: true },
                                          label: "Date range",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.selectedDateRangeOption,
                                          callback: function ($$v) {
                                            _vm.selectedDateRangeOption = $$v
                                          },
                                          expression: "selectedDateRangeOption",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.showDatePicker &&
                                  _vm.selectedDateRangeOption === "Custom range"
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4",
                                            lg: "3",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("date-range-picker", {
                                            attrs: { dates: _vm.dates },
                                            model: {
                                              value: _vm.dates,
                                              callback: function ($$v) {
                                                _vm.dates = $$v
                                              },
                                              expression: "dates",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.visibleTabs, function (tab, index) {
                        return _c(
                          "v-tab-item",
                          {
                            key: `tab-${index}`,
                            staticClass: "overflow-visible",
                          },
                          [
                            _c(
                              tab.component,
                              _vm._b(
                                { tag: "component" },
                                "component",
                                {
                                  rpid: _vm.selectedResourcePoolId,
                                  oid: _vm.selectedOrgId,
                                  sid: _vm.selectedSpaceId,
                                  dates: _vm.dates,
                                  rpManager: _vm.isRPManager,
                                  selectedRP: _vm.selectedRP,
                                  managedResourcePools:
                                    _vm.managedResourcePools,
                                },
                                false
                              )
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }