var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "250px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        outlined: "",
                        dense: "",
                        required: "",
                        label: "Range",
                        readonly: "",
                        "hide-details": "auto",
                      },
                      on: { "click:clear": _vm.clearDates },
                      model: {
                        value: _vm.dateRangeText,
                        callback: function ($$v) {
                          _vm.dateRangeText = $$v
                        },
                        expression: "dateRangeText",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dateRangePickerVisible,
        callback: function ($$v) {
          _vm.dateRangePickerVisible = $$v
        },
        expression: "dateRangePickerVisible",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("v-date-picker", {
                attrs: {
                  min: _vm.startDateMin,
                  max: _vm.startDateMax,
                  "first-day-of-week": "1",
                  color: "primary",
                  "full-width": _vm.$vuetify.breakpoint.smAndDown,
                },
                model: {
                  value: _vm.startDate,
                  callback: function ($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate",
                },
              }),
              _c("v-date-picker", {
                attrs: {
                  min: _vm.endDateMin,
                  max: _vm.endDateMax,
                  "first-day-of-week": "1",
                  color: "primary",
                  "full-width": _vm.$vuetify.breakpoint.smAndDown,
                },
                model: {
                  value: _vm.endDate,
                  callback: function ($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "secondary" },
                  on: { click: _vm.hideDateRangePicker },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.selectDates },
                },
                [_vm._v("Select")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }